<template>
  <div class="flex-parent">
    <div class="flex-child">
      <slot name="title"></slot>
    </div>
    <div class="flex-child">
      <b-select v-model="selected" aria-role="list" class="mb-1">
        <option v-if="includeAllOption" key="all" value="all">All</option>
        <option
          v-for="dataset in datasets"
          :key="dataset.id"
          aria-role="listitem"
          :value="dataset.id"
        >
          {{ dataset.label }}
        </option>
      </b-select>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import copy from 'fast-copy';

export default defineComponent({
  name: 'DataSetSelector',
  props: {
    includeAllOption: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selected: undefined,
    };
  },
  computed: {
    datasets() {
      return copy(this.$configData.customer.datasets);
    },
  },
  watch: {
    selected: {
      handler(newVal) {
        if (this.includeAllOption && !this.selected) {
          this.selected = 'all';
        } else if (!this.selected) {
          this.selected = this.$configData.customer.datasets[0].id;
        } else {
          this.selected = newVal;
        }
        this.$emit('input', this.selected);
      },
      immediate: true,
    },
  },
});
</script>

<style>
white {
  color: 'white' !important;
}

.flex-parent {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
}

.flex-child {
  flex: 0 1 auto;
}
</style>
